<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      back
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="3"
    >
      <v-stepper
        v-if="currentStep"
        vertical
        :value="currentStep"
        class="elevation-0"
      >
        <div
          v-for="(punto, puntoIndex) in rondaPuntos"
          :key="punto.idronda_punto"
        >
          <v-stepper-step
            editable
            :step="punto.punto_codigo"
            :color="punto.color"
          >
            <span>
              <v-chip
                v-show="!punto.fecha"
                class="chip-pendiente-realizado"
                label
                dark
                small
                color="red"
              >Pendiente</v-chip>
              <v-chip
                v-show="punto.fecha"
                class="chip-pendiente-realizado"
                label
                dark
                small
                color="green"
              >Realizado</v-chip>
              {{ punto.punto_descripcion }}
            </span>
          </v-stepper-step>
          <v-stepper-content
            :step="punto.punto_codigo"
          >
            <v-alert
              type="info"
              :value="!!punto.mronda_punto_mensaje"
            >
              {{ punto.mronda_punto_mensaje }}
            </v-alert>
            <v-alert
              color="warning"
              dark
              :value="!punto.codigo_scanner && hasNFC"
            >
              <v-row align="center">
                <v-col class="grow">
                  Sin código asociado
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    outlined
                    small
                    @click.stop="clickAsociarCodigoScanner(puntoIndex)"
                  >
                    Asociar
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-list
              v-if="punto.checks.length > 0"
              avatar
            >
              <v-subheader>Tareas/comprobaciones</v-subheader>
              <v-list-item
                v-for="check in punto.checks"
                :key="check.idps_check"
              >
                <v-list-item-avatar>
                  <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ check.descripcion }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn
              v-if="punto.latitud && punto.longitud"
              block
              color="green"
              dark
              @click.stop="clickIrMapaPunto(punto)"
            >
              <v-icon
                left
              >
                {{ $vuetify.icons.values.mapLocation }}
              </v-icon>
              ({{ punto.latitud.toFixed(6) }}, {{ punto.longitud.toFixed(6) }})
            </v-btn>
          </v-stepper-content>
        </div>
      </v-stepper>
      <b10-fab-button
        :icon="$vuetify.icons.values.qr"
        @click="clickQR"
      />
      <b10-fab-button
        v-if="hasNFC"
        left
        :icon="$vuetify.icons.values.nfc"
        @click="clickLeerNFC"
      />
    </b10-page-content>
    <b10-qr-reader
      :showing.sync="showingDialogs.scanQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './RondaRealizarData'
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { hasNFC, scanNFC } from '@/utils/nfc'
import { get, sync } from 'vuex-pathify'
import { currentDateTime } from '@/utils/date'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      toolbarOptions: {
        finalizarRonda: {
          title: 'Finalizar ronda',
          visible: true,
          icon: 'check'
        },
        annadirNovedad: {
          title: 'Añadir novedad',
          visible: true,
          icon: 'add'
        }
      },
      hasNFC: hasNFC(),
      ronda: null,
      rondaPuntos: [],
      punto: '',
      showingDialogs: {
        scanQR: false,
      },
      currentStep: ''
    }
  },
  computed: {
    presencia: get('presencia'),
    vigilanciaIdronda: sync('vigilancia/idronda'),
    puntosPendientes () {
      return _.filter(this.rondaPuntos, (punto) => {
        return punto.fecha === null
      })
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Realizar ronda'
      this.loadRonda(this.routeParams.idronda)
      this.toolbarOptions.annadirNovedad.visible = (
        this.presencia.idparteNovedad > 0
      )
    },
    async loadRonda (idronda) {
      this.ronda = await Data.rowRonda(this, idronda)
      this.title = `Iniciada el ${this.$options.filters.shortDate(this.ronda.fcomienzo)} a las ${this.$options.filters.shortTime(this.ronda.fcomienzo)}`
      const [datasetPuntos] = await Data.selectRondaPunto(this, idronda)
      let primerPuntoCodigo = ''
      for (const punto of datasetPuntos) {
        if (primerPuntoCodigo === '') {
          primerPuntoCodigo = punto.punto_codigo
        }
        punto.color = stringToHslColor(calculateMd5(punto.idmronda_punto.toString()))
        const [datasetChecks] = await Data.selectRondaPuntoCheck(
          this, punto.idronda_punto
        )
        punto.checks = datasetChecks
      }
      this.rondaPuntos = datasetPuntos
      this.currentStep = primerPuntoCodigo
    },
    async clickLeerNFC () {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          this.punto = tag.tagID
          this.$alert.showSnackbarSuccess('Etiqueta NFC leída')
          this.changePuntoControl()
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    async changePuntoControl () {
      if (this.punto) {
        const punto = _.find(this.rondaPuntos, { 'codigo_scanner': this.punto })
        if (punto) {
          // marcar punto realizado
          this.currentStep = punto.punto_codigo
          if (punto.fecha) {
            this.$alert.showSnackbarError(
              `El punto de control ya fue realizado
              el ${this.$options.filters.shortDate(punto.fecha)}
              a las ${this.$options.filters.shortTime(punto.fecha)}
              `
            )
          } else {
            let geolocation = await captureGeolocation(this)
            if (geolocation) {
              punto.fecha = currentDateTime()
              punto.latitud = geolocation.lat
              punto.longitud = geolocation.lng
              await Data.updateRondaPunto(
                this, punto.idronda_punto, punto.fecha, punto.latitud, punto.longitud
              )
              if (this.puntosPendientes.length === 0) {
                this.$nextTick(() => { this.finalizarRonda() })
              }
            }
          }
        } else {
          this.$alert.showSnackbarError('Punto de control incorrecto')
        }
        this.punto = ''
      }
    },
    clickIrMapaPunto (punto) {
      window.open(getLatLongURL(punto.latitud, punto.longitud), '_blank')
    },
    async finalizarRonda () {
      let continuar = true
      if (this.puntosPendientes.length > 0) {
        continuar = await this.$alert.showConfirm(
          `Hay ${this.puntosPendientes.length}
          ${this.$options.filters.pluralize('punto', this.puntosPendientes.length, 's')}
          ${this.$options.filters.pluralize('pendiente', this.puntosPendientes.length, 's')}.
          ¿Deseas finalizar la ronda?`
        )
      } else {
        continuar = await this.$alert.showConfirm('Ya has leído todos los puntos de control. ¿Deseas finalizar la ronda?')
      }
      if (continuar) {
        await Data.finalizarRonda(this, this.ronda.idronda)
        this.vigilanciaIdronda = null
        this.$appRouter.go(-1)
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.finalizarRonda) {
        this.finalizarRonda()
      } else if (option === this.toolbarOptions.annadirNovedad) {
        this.$appRouter.push({
          name: 'partesnovedad__lparte-novedad-add',
          params: {
            idparte_novedad: this.presencia.idparteNovedad
          }
        })
      }
    },
    async clickAsociarCodigoScanner (puntoIndex) {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          const formattedTag = tag.tagID
          Data.updatePunto(this, this.rondaPuntos[puntoIndex].idpunto, formattedTag)
          this.$set(this.rondaPuntos[puntoIndex], 'codigo_scanner', formattedTag)
          this.$alert.showSnackbarSuccess('Etiqueta NFC asociada al punto de control')
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    async decodeQR (value) {
      if (value) {
        this.punto = value
        this.changePuntoControl()
      }
    },
  }
}
</script>

<style scoped>
.chip-pendiente-realizado {
  text-shadow: none;
}
.input-punto >>> input {
  -webkit-text-security: disc;
}
.v-stepper__content {
  padding-left: 0;
}
</style>
