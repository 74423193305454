import { APIFilter } from '@/utils/api'

export default {
  async rowRonda (Vue, idronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda', idronda)
    const resp = await Vue.$api.call('ronda.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectRondaPunto (Vue, idronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda', idronda)
    const resp = await Vue.$api.call('rondaPunto.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectRondaPuntoCheck (Vue, idrondaPunto) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda_punto', idrondaPunto)
    const resp = await Vue.$api.call('rondaPuntoCheck.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async updateRondaPunto (Vue, idrondaPunto, fecha, latitud, longitud) {
    await Vue.$api.call(
      'rondaPunto.update',
      {
        values: {
          'idronda_punto': idrondaPunto,
          fecha,
          latitud,
          longitud
        }
      }
    )
  },
  async updatePunto (Vue, idpunto, codigoScanner) {
    await Vue.$api.call(
      'punto.update',
      {
        values: {
          'idpunto': idpunto,
          'codigo_scanner': codigoScanner
        }
      }
    )
  },
  async finalizarRonda (Vue, idronda) {
    await Vue.$api.call(
      'ronda.finalizar', { 'idronda': idronda }
    )
  }
}
